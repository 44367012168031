<template>
    <van-row class="tic-divider-main" type="flex">
        <van-col class="tic-divider__account-divider-circle-start"><svg t="1610121744372" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1143" width="24px" height="24px"><path d="M0 0a512 512 0 1 1 0 1024Z" fill="#17161c" p-id="1144"></path></svg></van-col>
        <van-col class="tic-divider__account-divider-dashed"/>
        <van-col class="tic-divider__account-divider-circle-end"><svg t="1610121744372" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1143" width="24px" height="24px"><path d="M1024 0a512 512 0 1 0 0 1024Z" fill="#17161c" p-id="1144"></path></svg></van-col>
    </van-row>
    
</template>

<script>
import {Row, Col} from "vant";
export default {
    name: "tic-divider",
    components: {
        [Col.name]: Col,
        [Row.name]: Row
    },
    data() {
        return {};
    }, 
    methods: {}
}
</script>

<style lang="less">

.tic-divider__account-divider-wrapper {
    margin-left: 16px;
    width: 24px;
    height: 100%;
    position: relative;
}

.tic-divider-divider-circle-top {
    width: 24px;
    height: 24px;
    z-index: 100;
    display: inline-block;
}
.tic-divider__account-divider-circle-bottom {
    z-index: 100;
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: 0;
    transform: translateY(50%);
}


.tic-divider__account-divider-dashed {
    border-top: 1px dashed #0a07070f;
    transform: translateY(50%);
    flex-grow: 1;
}
</style>